/* eslint-disable max-lines-per-function */

import { Entity } from '@camberi/firecms';
import FlashOnOutlinedIcon from '@mui/icons-material/FlashOnOutlined';
import OpenInNewOutlinedIcon from '@mui/icons-material/OpenInNewOutlined';
import SellOutlinedIcon from '@mui/icons-material/SellOutlined';
import {
  Button,
  Divider,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TablePagination,
  TableRow,
  TableSortLabel,
  Typography,
} from '@mui/material';
import { blue } from '@mui/material/colors';
import { FC, Key, useState } from 'react';

import useRoasterOrders from '../../../../hooks/useRoasterOrders';
import { OrderDocument } from '../../../../interfaces/Firestore';
import { OrderStatus } from '../../../../schemas/quizResult.schema';
import { Roaster } from '../../../../schemas/roaster.schema';
import { ORDER_LIST } from '../../constants/order';
import SimpleSelect from '../SimpleSelect';

export interface OrderDetail {
  orderRoasterIndex: number;
  orderTimeStamp: Date;
  orderPaidTimestamp: Date;
  orderStatus: string;
}

export const getOrderDateTime = (timestamp: unknown): Date => {
  const orderDate = timestamp as { seconds: number };
  return new Date(orderDate.seconds * 1000);
};

type Order = 'asc' | 'desc';

interface Props {
  roaster: Entity<Roaster> | undefined;
  handleOnClick: (value: string, row: OrderDocument, orderDetail: OrderDetail) => void;
}

const OrderList: FC<Props> = ({ roaster, handleOnClick }) => {
  const { orders } = useRoasterOrders(roaster?.id);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [order, setOrder] = useState<Order>('asc');
  const [orderBy, setOrderBy] = useState('status');

  if (!roaster && !orders) return <Typography>Roaster Data Missing.</Typography>;
  const rows = orders ? [...orders] : [];
  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;
  const handleChangePage = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
    setPage(newPage);
  };

  const setOrderDateTime = (timestamp: unknown) => {
    const orderDateTime = getOrderDateTime(timestamp);
    const date = orderDateTime.toLocaleDateString('th-TH', {
      day: 'numeric',
      month: 'short',
      weekday: 'long',
      year: 'numeric',
    });
    return date.replace(`${orderDateTime.getFullYear() + 543}`, `${orderDateTime.getFullYear()}`);
  };

  const getOrderStatus = (orderItem: unknown): string => {
    const order = JSON.parse(JSON.stringify(orderItem));
    let status = order.status as string;
    const orderDateTime = getOrderDateTime(order.orderTimestamp);
    const yesterday = new Date(new Date().getTime() - 24 * 60 * 60 * 1000);
    if (status === OrderStatus.Shipped && getOrderTicket(order)?.deliveryComplete) status = 'Delivered';
    else if (orderDateTime < yesterday && status === OrderStatus.Processed) status = 'Late';
    return status;
  };

  const getOrderTicket = (order: OrderDocument) => {
    return order.tickets[getOrderRoasterIndex(order?.metadata?.roasterIds as string[], roaster?.id || '')];
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const getOrderRoasterIndex = (orderRoasterIds: string[], roasterId: string): number => {
    const index = orderRoasterIds?.findIndex((item: unknown) => item === roasterId);
    return index;
  };

  const selectedOrderDetail = (order: OrderDocument): OrderDetail => {
    const orderRoasterIndex = getOrderRoasterIndex(order?.metadata?.roasterIds as string[], roaster?.id || '');
    const orderTimeStamp = getOrderDateTime(order.orderTimestamp);
    const orderPaidTimestamp = getOrderDateTime(order.paidTimestamp);
    const orderStatus = getOrderStatus(order);
    return { orderPaidTimestamp, orderRoasterIndex, orderStatus, orderTimeStamp };
  };

  const createSortHandler = (property: string) => (event: React.MouseEvent<unknown>) => {
    handleRequestSort(event, property);
  };

  const handleRequestSort = (event: React.MouseEvent<unknown>, property: string) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  function stableSort<T>(array: unknown[], comparator: (a: T, b: T) => number) {
    const stabilizedThis = array.map((el: unknown, index) => [el, index] as [T, number]);
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) {
        return order;
      }
      return a[1] - b[1];
    });
    return stabilizedThis.map((el: Array<T | number>) => el[0] as unknown as OrderDocument);
  }

  function getComparator(
    order: Order,
    orderBy: Key,
  ): (a: { [key in Key]: number | string }, b: { [key in Key]: number | string }) => number {
    return order === 'desc'
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
  }

  function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
    if (orderBy === 'status') {
      if (getOrderStatus(b) < getOrderStatus(a)) {
        return -1;
      }
      if (getOrderStatus(b) > getOrderStatus(a)) {
        return 1;
      }
    } else {
      if (b[orderBy] < a[orderBy]) {
        return -1;
      }
      if (b[orderBy] > a[orderBy]) {
        return 1;
      }
    }
    return 0;
  }

  return (
    <div className="bg-gray-1 w-full pt-12 pl-14 pr-[67px] pb-20">
      <div className="flex justify-between">
        <div className="font-bold text-[32px] text-gray-700">{ORDER_LIST.TITLE}</div>
      </div>
      <div className="pt-8">
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 500 }}>
            <TableBody>
              <TableRow>
                <TableCell key={'status'} className="text-gray-300 h-10">
                  <TableSortLabel
                    active={orderBy === 'status'}
                    direction={orderBy === 'status' ? order : 'asc'}
                    onClick={createSortHandler('status')}
                  >
                    {ORDER_LIST.STATUS}
                  </TableSortLabel>
                </TableCell>
                <TableCell key={'orderTimestamp'} className="text-gray-300 h-10">
                  <TableSortLabel
                    active={orderBy === 'orderTimestamp'}
                    direction={orderBy === 'orderTimestamp' ? order : 'asc'}
                    onClick={createSortHandler('orderTimestamp')}
                  >
                    {ORDER_LIST.DATE}
                  </TableSortLabel>
                </TableCell>
                <TableCell className="text-gray-300 h-10">{ORDER_LIST.DELIVERY_ADDRESS}</TableCell>
                <TableCell className="text-gray-300 h-10">{ORDER_LIST.PRODUCTS}</TableCell>
                <TableCell className="text-gray-300 h-10">{ORDER_LIST.TOTAL}</TableCell>
                <TableCell className="text-gray-300 h-10">{ORDER_LIST.PARCEL_SIZE}</TableCell>
                <TableCell className="text-gray-300 h-10">{ORDER_LIST.PRINT_LABEL}</TableCell>
              </TableRow>
              {(rowsPerPage > 0
                ? stableSort(rows, getComparator(order, orderBy)).slice(
                    page * rowsPerPage,
                    page * rowsPerPage + rowsPerPage,
                  )
                : rows
              ).map((row) => (
                <TableRow key={row?.id}>
                  <TableCell component="th" scope="row" className="text-gray-500 align-top">
                    {getOrderStatus(row) === ORDER_LIST.LATE && (
                      <Button className="font-normal text-base normal-case no-underline w-20" variant="contained">
                        {ORDER_LIST.LATE}
                      </Button>
                    )}
                    {getOrderStatus(row) === OrderStatus.Processed && (
                      <Button
                        className="font-normal text-base bg-blue-300 normal-case no-underline w-20"
                        variant="contained"
                      >
                        {OrderStatus.Processed}
                      </Button>
                    )}
                    {getOrderStatus(row) === ORDER_LIST.DELIVERED && (
                      <Button
                        className="font-normal text-base bg-green-700 normal-case no-underline w-20"
                        variant="contained"
                      >
                        {ORDER_LIST.DELIVERED}
                      </Button>
                    )}
                    {getOrderStatus(row) === OrderStatus.Shipped && (
                      <Button
                        className="font-normal text-base bg-green-500 normal-case no-underline w-20"
                        variant="contained"
                      >
                        {OrderStatus.Shipped}
                      </Button>
                    )}
                  </TableCell>
                  <TableCell component="th" scope="row" className="text-gray-500 align-top">
                    <div>{setOrderDateTime(row.orderTimestamp)}</div>
                  </TableCell>
                  <TableCell component="th" scope="row" className="text-gray-500 align-top">
                    <div className="flex">
                      <div className="pr-1.5">{row.shippingAddress.firstName}</div>
                      <div>{row.shippingAddress.lastName}</div>
                    </div>
                    <br />
                    <div>
                      <div>{row.shippingAddress.address}</div>
                    </div>
                    <div>
                      <div>{row.shippingAddress.subdistrict}</div>
                    </div>
                    <div>
                      <div>{row.shippingAddress.district},</div>
                      <div>{row.shippingAddress.province}</div>
                    </div>
                    <div>
                      <div>{row.shippingAddress.zipCode}</div>
                    </div>
                    <br />
                    <div>
                      <div>{row.shippingAddress.phoneNumber}</div>
                    </div>
                  </TableCell>
                  <TableCell component="th" scope="row" className="text-gray-500 align-top px-5">
                    {getOrderTicket(row)?.items.map((item, index) => (
                      <div key={index}>
                        {index !== 0 && <Divider className="my-2" />}
                        <div>
                          x{item.quantity} <span className="font-bold">{item.name}</span>
                        </div>
                        {item.metadata?.quantityGrams ? <div>{item.metadata?.quantityGrams} g</div> : ''}
                        {item.metadata?.quantityMilliliters ? <div>{item.metadata?.quantityMilliliters} g</div> : ''}
                        {item.metadata?.brewMethod ? (
                          <div className="font-bold">
                            {ORDER_LIST.GRIND} {item.metadata?.brewMethod}
                          </div>
                        ) : (
                          <div>{ORDER_LIST.NOT_GRIND}</div>
                        )}
                        <div>{item.unitPrice} THB</div>
                      </div>
                    ))}
                  </TableCell>
                  <TableCell component="th" scope="row" className="text-gray-500 align-top">
                    <div className="pl-2">
                      <div className="pl-2">{row.total}</div>
                    </div>
                  </TableCell>
                  <TableCell component="th" scope="row" className="text-gray-500 align-top">
                    <SimpleSelect
                      name={ORDER_LIST.PARCEL.PARCEL_NAME}
                      value={ORDER_LIST.PARCEL_OPTION[0]}
                      options={ORDER_LIST.PARCEL_OPTION}
                      onChange={() => {}}
                    />
                  </TableCell>
                  <TableCell component="th" scope="row" className="text-gray-500 align-top relative">
                    <div>
                      <Button
                        className="bg-blue-500 font-normal text-base rounded-full normal-case no-underline w-[90px]"
                        variant="contained"
                        size="small"
                        endIcon={<SellOutlinedIcon />}
                        onClick={() => handleOnClick('Label', row, selectedOrderDetail(row))}
                      >
                        <Typography>{ORDER_LIST.LABEL}</Typography>
                      </Button>
                    </div>
                    <div>
                      <Button
                        className="font-normal text-base rounded-full normal-case mt-5 w-[90px]"
                        variant="outlined"
                        color="info"
                        size="small"
                        endIcon={<FlashOnOutlinedIcon className="bg-white border-blue-500" />}
                        onClick={() => handleOnClick('Postal', row, selectedOrderDetail(row))}
                      >
                        <Typography>{ORDER_LIST.POSTAL}</Typography>
                      </Button>
                    </div>
                    <div>
                      <div></div>
                      <IconButton
                        className="absolute bottom-3 right-3"
                        aria-label="close"
                        onClick={() => handleOnClick('Detail', row, selectedOrderDetail(row))}
                      >
                        <OpenInNewOutlinedIcon sx={{ color: blue[500], fontSize: '20px' }} />
                      </IconButton>
                    </div>
                  </TableCell>
                </TableRow>
              ))}
              {emptyRows > 0 && (
                <TableRow style={{ height: 53 * emptyRows }}>
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
            <TableFooter>
              <TableRow>
                <TablePagination
                  rowsPerPageOptions={[5, 10, 25, { label: 'All', value: -1 }]}
                  colSpan={7}
                  count={rows.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  SelectProps={{
                    inputProps: {
                      'aria-label': 'rows per page',
                    },
                    native: true,
                  }}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                />
              </TableRow>
            </TableFooter>
          </Table>
        </TableContainer>
      </div>
    </div>
  );
};

export default OrderList;
