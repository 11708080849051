/* eslint-disable max-lines-per-function */
import { Entity, useDataSource } from '@camberi/firecms';
import ContentCopyOutlinedIcon from '@mui/icons-material/ContentCopyOutlined';
import EditOutlined from '@mui/icons-material/EditOutlined';
import {
  Button,
  Paper,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TablePagination,
  TableRow,
  Typography,
} from '@mui/material';
import { FC, useEffect, useState } from 'react';

import { getProductStats, ProductSchema, productSchema } from '../../../../schemas/product.schema';
import { Roaster } from '../../../../schemas/roaster.schema';
import getImageUrl from '../../../../utils/getImageUrl';
import { PRODUCT_LIST } from '../../constants/product';
import { AlertFeedback } from '../AlertFeedback';

interface Props {
  roaster: Entity<Roaster> | undefined;
  onAddProduct: (value: boolean) => void;
  onEditProduct: (productId: string) => void;
}

const ProductList: FC<Props> = ({ roaster, onAddProduct, onEditProduct }) => {
  const { saveEntity, fetchCollection } = useDataSource();

  const [page, setPage] = useState(0);
  const [alertFeedback, setAlertFeedback] = useState('');
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [productList, setProductList] = useState<Entity<ProductSchema>[] | undefined>([]);
  const [productStats, setProductStats] = useState({} as Record<string, unknown | null>);

  const fetchProductsList = () => {
    fetchCollection({ path: `roasters/${roaster?.id}/products`, schema: productSchema }).then((products) => {
      setProductList(products);
    });
  };

  useEffect(() => {
    fetchProductsList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    productList?.forEach(async (product: Entity<ProductSchema>) => {
      const stats = await getProductStats(product);
      productStats[product.id] = stats;
      setProductStats({ ...productStats });
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [productList]);

  if (!roaster && !productList) return <Typography>Roaster Data Missing.</Typography>;
  const rows = productList ? [...productList] : [];
  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

  const handlePublish = (product: Entity<ProductSchema>) => {
    if (product) {
      const values = { ...product.values, published: !product.values.published };

      saveEntity({
        entityId: product.id,
        path: `roasters/${roaster?.id}/products`,
        schema: productSchema,
        status: 'existing',
        values,
      });

      fetchProductsList();
      setAlertFeedback('อัปเดตข้อมูลเรียบร้อยแล้ว');
    }
  };

  const handleChangePage = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleOnDuplicateProduct = (productId: string) => {
    const product = productList?.find((productList) => productList.id === productId);

    if (product) {
      saveEntity({
        entityId: new Date().getTime().toString(),
        path: `roasters/${roaster?.id}/products`,
        schema: productSchema,
        status: 'new',
        values: product.values,
      }).then((entity) => {
        if (productList) {
          setProductList([...productList, entity]);
        }
        setAlertFeedback('คัดลอกข้อมูลสำเร็จ');
      });
    }
  };

  const handleOnCloseAlert = (event?: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }

    setAlertFeedback('');
  };

  return (
    <div className="bg-gray-1 w-full pt-12 pl-14 pr-[67px]">
      <div className="flex justify-between">
        <div className="font-bold text-[32px] text-gray-700">My Products</div>
        <Button
          onClick={() => onAddProduct(true)}
          className="bg-blue-500 font-normal text-base rounded-full normal-case no-underline"
          variant="contained"
        >
          Add new product
        </Button>
      </div>
      <div className="pt-8">
        <AlertFeedback open={!!alertFeedback} message={alertFeedback} type="success" onClose={handleOnCloseAlert} />

        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 500 }}>
            <TableBody>
              <TableRow>
                <TableCell className="text-gray-300">Product name</TableCell>
                <TableCell className="text-gray-300">Size&nbsp;(g)</TableCell>
                <TableCell className="text-gray-300">Stock</TableCell>
                <TableCell className="text-gray-300">Price</TableCell>
                <TableCell className="text-gray-300">Manage</TableCell>
                <TableCell className="text-gray-300">Published</TableCell>
              </TableRow>
              {(rowsPerPage > 0 ? rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage) : rows).map(
                (row) => (
                  <TableRow key={row?.id}>
                    <TableCell component="th" scope="row" className="text-gray-500 align-top flex">
                      <div>{row.values.image && <img src={getImageUrl(row.values.image)} width="64" />}</div>
                      <div className="pl-2">{row.values.name}</div>
                    </TableCell>
                    <TableCell component="th" scope="row" className="text-gray-500 align-top">
                      {row.values.quantityOptions.map(({ amount }, index) => (
                        <div key={index}>{amount}</div>
                      ))}
                    </TableCell>
                    <TableCell component="th" scope="row" className="text-gray-500 align-top">
                      {/* <div>{productStats[row?.id]?.stock}</div> */}
                      {row.values.quantityOptions.map(({ stock }, index) => (
                        <div key={index}>{stock}</div>
                      ))}
                    </TableCell>
                    <TableCell component="th" scope="row" className="text-gray-500 align-top">
                      {row.values.quantityOptions.map(({ price }, index) => (
                        <div key={index}>฿{price}</div>
                      ))}
                    </TableCell>
                    <TableCell component="th" scope="row" className="text-gray-500 align-top">
                      <div>
                        <Button
                          variant="text"
                          color="info"
                          size="small"
                          endIcon={<EditOutlined />}
                          className="text-gray-500 normal-case"
                          onClick={() => onEditProduct(row?.id)}
                        >
                          {PRODUCT_LIST.EDIT}
                        </Button>
                      </div>
                      <div>
                        <Button
                          variant="text"
                          color="info"
                          size="small"
                          endIcon={<ContentCopyOutlinedIcon />}
                          className="text-gray-500 normal-case"
                          onClick={() => handleOnDuplicateProduct(row?.id)}
                        >
                          {PRODUCT_LIST.DUPLICATE}
                        </Button>
                      </div>
                    </TableCell>
                    <TableCell component="th" scope="row" className="text-gray-500 align-top">
                      <Switch checked={row.values.published} color="info" onChange={() => handlePublish(row)} />
                    </TableCell>
                  </TableRow>
                ),
              )}
              {emptyRows > 0 && (
                <TableRow style={{ height: 53 * emptyRows }}>
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
            <TableFooter>
              <TableRow>
                <TablePagination
                  rowsPerPageOptions={[5, 10, 25, { label: 'All', value: -1 }]}
                  colSpan={6}
                  count={rows.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  SelectProps={{
                    inputProps: {
                      'aria-label': 'rows per page',
                    },
                    native: true,
                  }}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                />
              </TableRow>
            </TableFooter>
          </Table>
        </TableContainer>
      </div>
    </div>
  );
};

export default ProductList;
