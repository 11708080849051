/* eslint-disable sort-keys-fix/sort-keys-fix */

import { buildEntityCallbacks, buildSchema } from '@camberi/firecms';

import { ParcelSize } from '../interfaces/Parcel';
import { slugRe } from '../utils/buildSlug';
import { ProductCategory } from './product.schema';

export interface Warehouse {
  name: string;
  address: string;
  subdistrict: string;
  district: string;
  province: string;
  zipCode: string;
  phoneNumber: string;
}

export interface Roaster {
  name: string;
  active: boolean;
  categories: string[];
  slug: string;
  description: string;
  logo: string;
  awards?: string[];
  shopLink?: string;
  warehouses: Warehouse[];
  parcelSizes?: ParcelSize[];
}

export const roasterSchema = buildSchema<Roaster>({
  defaultValues: { active: false },
  name: 'Roaster',
  properties: {
    name: { dataType: 'string', title: "Roaster's Name", validation: { required: true, trim: true } },
    active: {
      dataType: 'boolean',
      title: 'Active',
      validation: { required: true },
    },
    categories: {
      dataType: 'array',
      disabled: true,
      of: { dataType: 'string', config: { enumValues: ProductCategory } },
      title: 'Categories',
    },
    slug: {
      dataType: 'string',
      title: 'Slug',
      validation: { matches: slugRe, trim: true, required: true, unique: true },
    },
    description: {
      columnWidth: 300,
      dataType: 'string',
      title: 'Description',
      validation: { required: true, trim: true },
    },
    logo: {
      config: {
        storageMeta: {
          acceptedFiles: ['image/*'],
          fileName: () => `logo-${Date.now()}`,
          mediaType: 'image',
          metadata: {
            cacheControl: 'max-age=1000000',
          },
          storagePath: (context) => `roasters/${context.entityId}`,
        },
      },
      dataType: 'string',
      title: 'Logo',
      validation: { required: true },
    },
    awards: { dataType: 'array', of: { dataType: 'string', validation: { trim: true } }, title: 'Awards' },
    shopLink: { config: { url: true }, dataType: 'string', validation: { trim: true } },
    warehouses: {
      dataType: 'array',
      title: 'Warehouses',
      of: {
        dataType: 'map',
        properties: {
          name: {
            title: 'Name',
            dataType: 'string',
            validation: { max: 50 },
          },
          address: {
            title: 'Address',
            dataType: 'string',
            validation: { max: 200 },
          },
          subdistrict: {
            title: 'Subdistrict',
            dataType: 'string',
            validation: { max: 150 },
          },
          district: {
            title: 'District',
            dataType: 'string',
            validation: { max: 150 },
          },
          province: {
            title: 'Province',
            dataType: 'string',
            validation: { max: 150 },
          },
          zipCode: {
            title: 'ZipCode',
            dataType: 'string',
            validation: { max: 20 },
          },
          phoneNumber: {
            title: 'PhoneNumber',
            dataType: 'string',
            validation: { max: 20 },
          },
        },
      },
    },
    parcelSizes: {
      dataType: 'array',
      of: {
        dataType: 'map',
        properties: {
          label: { dataType: 'string', title: 'Label', validation: { required: true, trim: true } },
          width: { dataType: 'number', title: 'Width' },
          height: { dataType: 'number', title: 'Height' },
          length: { dataType: 'number', title: 'Length' },
          weight: { dataType: 'number', title: 'Weight', validation: { required: true } },
        },
        validation: { uniqueInArray: true },
      },
      title: 'ParcelSizes',
      validation: { required: true, min: 1 },
    },
  },
});

export const roasterCallbacks = buildEntityCallbacks<Roaster>({
  onPreSave: async ({ values }) => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { categories, ...newValues } = values;

    if (!newValues.parcelSizes) newValues.parcelSizes = [];

    return newValues;
  },
});
